import { useState, useEffect } from "react";
import supabase from "./supabase";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import "./style.css";
// import { questions } from "./questions"; // Import the questions array

function App() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(function () {
    async function getQuestions() {
      let query = supabase.from("cards").select("*");

      const { data: questions, error } = await query;

      if (!error) {
        const formattedQuestions = questions.map((question) => ({
          question: question.question,
          options: question.options, // assuming options are stored as an array in the database
          answer: question.answer,
          category: question.category,
          difficulty: question.difficulty,
        }));
        setQuestions(formattedQuestions);
      }
    }
    getQuestions();
  }, []);

  const resetSelections = () => {
    setSelectedCategories([]);
    setSelectedDifficulty([]);
  };

  console.log(questions);

  return (
    <Router>
      <Routes>
        <Route
          path=""
          element={
            <>
              <main className="main">
                <StartButton to="/category" resetSelections={resetSelections} />
              </main>
            </>
          }
        />
        <Route
          path="/category"
          element={
            <>
              <Header title="choose a category:" />
              <main className="main">
                <CategoryButtons
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
                <NextButton
                  className="next-button"
                  to="/difficulty"
                  isDisabled={selectedCategories.length === 0}
                />
              </main>
            </>
          }
        />
        <Route
          path="/difficulty"
          element={
            <>
              <Header title="choose your difficulty:" />
              <main className="main">
                <DifficultyButtons
                  selectedDifficulty={selectedDifficulty}
                  setSelectedDifficulty={setSelectedDifficulty}
                />
                <BackButton />
                <NextButton
                  to="/trivia"
                  isDisabled={selectedDifficulty.length === 0}
                />
              </main>
            </>
          }
        />
        <Route
          path="/trivia"
          element={
            <>
              <Header title="trivia questions" />
              <TriviaPage
                selectedCategories={selectedCategories}
                selectedDifficulty={selectedDifficulty}
                resetSelections={resetSelections}
                questions={questions}
              />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

function Header({ title }) {
  return (
    <header className="header">
      <h1>{title}</h1>
    </header>
  );
}

const categories = [
  { name: "quran", color: "#ff5757" },
  { name: "seerah", color: "#5271ff" },
  { name: "prophets", color: "#7ed957" },
  { name: "sahabah", color: "#ffde59" },
];

const difficulties = [
  { name: "easy", color: "#4caf50" },
  { name: "medium", color: "#ff9800" },
  { name: "hard", color: "#f44336" },
];

function SelectButton({ name, color, isClicked, handleClick }) {
  const buttonStyle = {
    color: isClicked ? "#ffffff" : color,
    backgroundColor: isClicked ? color : "#ffffff",
    padding: "10px 20px",
    borderRadius: "100px",
    cursor: "pointer",
    margin: "10px auto",
    border: `3px solid ${color}`,
  };

  return (
    <button style={buttonStyle} onClick={() => handleClick(name)}>
      {name}
    </button>
  );
}

function CategoryButtons({ selectedCategories, setSelectedCategories }) {
  const handleClick = (name) => {
    setSelectedCategories((prev) =>
      prev.includes(name)
        ? prev.filter((category) => category !== name)
        : [...prev, name]
    );
  };

  return (
    <ul>
      {categories.map((cat) => (
        <li key={cat.name} className="category">
          <SelectButton
            name={cat.name}
            color={cat.color}
            isClicked={selectedCategories.includes(cat.name)}
            handleClick={handleClick}
          />
        </li>
      ))}
    </ul>
  );
}

function DifficultyButtons({ selectedDifficulty, setSelectedDifficulty }) {
  const handleClick = (name) => {
    setSelectedDifficulty((prev) =>
      prev.includes(name)
        ? prev.filter((difficulty) => difficulty !== name)
        : [...prev, name]
    );
  };

  return (
    <ul>
      {difficulties.map((diff) => (
        <li key={diff.name} className="difficulty">
          <SelectButton
            name={diff.name}
            color={diff.color}
            isClicked={selectedDifficulty.includes(diff.name)}
            handleClick={handleClick}
          />
        </li>
      ))}
    </ul>
  );
}

// Utility function to shuffle an array (Fisher-Yates algorithm)
function shuffleArray(array) {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

function getCategoryColor(category) {
  const categoryObj = categories.find((cat) => cat.name === category);
  return categoryObj ? categoryObj.color : "#007bff"; // Default color if category is not found
}

function TriviaPage({
  selectedCategories,
  selectedDifficulty,
  resetSelections,
  questions,
}) {
  const [currentCard, setCurrentCard] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);

  useEffect(() => {
    const filteredQuestions = questions.filter(
      (question) =>
        selectedCategories.includes(question.category) &&
        selectedDifficulty.includes(question.difficulty)
    );
    setShuffledQuestions(shuffleArray(filteredQuestions)); // Shuffle the filtered questions
  }, [selectedCategories, selectedDifficulty]);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleNextCard = () => {
    if (flipped) {
      setFlipped(false);
      setTimeout(() => {
        setCurrentCard((prev) => (prev + 1) % shuffledQuestions.length);
      }, 500);
    } else {
      setFlipped(false);
      setCurrentCard((prev) => (prev + 1) % shuffledQuestions.length);
    }
  };

  const handlePrevCard = () => {
    if (flipped) {
      setFlipped(false);
      setTimeout(() => {
        setCurrentCard(
          (prev) =>
            (prev - 1 + shuffledQuestions.length) % shuffledQuestions.length
        );
      }, 500);
    } else {
      setFlipped(false);
      setCurrentCard(
        (prev) =>
          (prev - 1 + shuffledQuestions.length) % shuffledQuestions.length
      );
    }
  };

  const navigate = useNavigate();

  const handleReset = () => {
    resetSelections();
    navigate("/category");
  };

  const currentCategory = shuffledQuestions[currentCard]?.category;
  const categoryColor = getCategoryColor(currentCategory);

  return (
    <main className="main">
      <p>category: {selectedCategories.join(", ")}</p>
      <p>difficulty: {selectedDifficulty.join(", ")}</p>
      {shuffledQuestions.length > 0 ? (
        <>
          <div
            className={`card ${flipped ? "flipped" : ""}`}
            onClick={handleFlip}
          >
            <div
              className="card-front question-card"
              style={{
                backgroundColor: categoryColor,
              }}
            >
              <p className="front-question">
                {shuffledQuestions[currentCard].question}
              </p>
              <ul>
                {shuffledQuestions[currentCard].options.map((option, index) => (
                  <li className="multi-answers" key={index}>
                    {option}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="card-back question-card"
              style={{
                backgroundColor: categoryColor,
              }}
            >
              <p>Correct Answer: {shuffledQuestions[currentCard].answer}</p>
            </div>
          </div>
          <div className="card-navigation">
            <button onClick={handlePrevCard} style={buttonStyle}>
              Previous Card
            </button>
            <button onClick={handleNextCard} style={buttonStyle}>
              Next Card
            </button>
            <button
              className="restart"
              onClick={handleReset}
              style={restartStyle}
            >
              Restart
            </button>
          </div>
        </>
      ) : (
        <p>No questions available for the selected category and difficulty.</p>
      )}
    </main>
  );
}

function NextButton({ to, isDisabled }) {
  return (
    <Link to={isDisabled ? "#" : to}>
      <button
        style={{ ...buttonStyle, opacity: isDisabled ? 0.5 : 1 }}
        disabled={isDisabled}
      >
        Next
      </button>
    </Link>
  );
}

function BackButton() {
  return (
    <Link to="/category">
      <button style={buttonStyle}>Back</button>
    </Link>
  );
}

function StartButton({ resetSelections }) {
  const handleClick = () => {
    resetSelections();
  };

  return (
    <Link to="/category">
      <button style={startStyle} onClick={handleClick}>
        Start
      </button>
    </Link>
  );
}

const startStyle = {
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  margin: "10px",
  backgroundColor: "#007bff",
  color: "#ffffff",
  margin: "100px auto",
};

const buttonStyle = {
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  margin: "10px",
  backgroundColor: "#007bff",
  color: "#ffffff",
};

const restartStyle = {
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  backgroundColor: "#007bff",
  color: "#ffffff",
};

export default App;
